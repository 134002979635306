body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.header {
  background-image: url('header3.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding-right: 20px; 
  padding-top: 20px; 
  padding-bottom: 10px;
  height: 80px;
}

.header-mobile {
  background-image: url('header3.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  /* padding-right: 20px;  */
  /* padding-top: 20px;  */
  /* padding-bottom: 10px; */
  /* height: 50px; */
}

.content {
  margin: 10px;
}

.table {
  display: table;
}

.table-row {
  display: table-row;
}

.table-cell {
  display: table-cell;
  vertical-align: top;
}

